import Link from "next/link";
// import dynamic from "next/dynamic";
import { ContactSupportDialog } from "../contact/support/ContactSupportDialog";
import { Typography } from "@/components/ui/typography";

// const ContactSupportDialog = dynamic(() =>
//   import("@/features/contact/support/ContactSupportDialog").then(
//     ({ ContactSupportDialog }) => ContactSupportDialog,
//   ),
// );
// const Typography = dynamic(() =>
//   import("@/components/ui/typography").then(({ Typography }) => Typography),
// );

export function Page400() {
  return (
    <main className="flex flex-col items-center gap-8">
      <div className="max-w-lg space-y-3 text-center">
        <Typography variant="code">400</Typography>
        <Typography variant="h1">Oh No! Unexpected Error.</Typography>
        <Typography variant="base">
          {
            "It seems we're experiencing some technical difficulties. Not to worry,"
          }
          <br />
          {
            "our team is working on it. In the meantime, try refreshing the page or"
          }
          <br />

          {"visiting us a bit later."}
        </Typography>
      </div>
      <div className="flex items-center gap-4">
        <Link href="/">{"Go back home"}</Link>
        <ContactSupportDialog />
      </div>
    </main>
  );
}
