import { type ComponentPropsWithoutRef } from "react";
import { cn } from "../../lib/utils";
import { Typography } from "@/components/ui/typography";

// import dynamic from "next/dynamic";
// const Typography = dynamic(
//   import("@/components/ui/typography").then(({ Typography }) => Typography),
// );

export const Layout = async (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={cn(
        "max-w-4xl flex-wrap w-full flex gap-4 m-auto px-4 mt-4",
        props.className,
      )}
    />
  );
};

export const LayoutHeader = async (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      suppressHydrationWarning
      className={cn(
        "flex items-start gap-2 flex-col w-full md:flex-1 min-w-[200px]",
        props.className,
      )}
    />
  );
};

export const LayoutTitle = async (props: ComponentPropsWithoutRef<"h1">) => {
  return <Typography {...props} variant="h2" className={cn(props.className)} />;
};

export const LayoutDescription = async (
  props: ComponentPropsWithoutRef<"p">,
) => {
  return <Typography {...props} className={cn(props.className)} />;
};

export const LayoutActions = async (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={cn("flex items-center", props.className)} />
  );
};

export const LayoutContent = async (props: ComponentPropsWithoutRef<"div">) => {
  return <div {...props} className={cn("w-full", props.className)} />;
};
