import { cn } from "@/lib/utils";
import { type VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import Link, { LinkTargetProps, type LinkProps } from "@/atoms/Link";
import {
  type ElementType,
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
} from "react";

export enum AnimationText {
  PULLUP = "pull-up",
  TYPING = "typing",
}

const typographyVariants = cva("", {
  variants: {
    variant: {
      h1: "scroll-m-20 font-sans text-4xl font-extrabold tracking-tight max-md:text-3xl lg:text-5xl",
      h2: "offset-4 scroll-m-20 font-sans text-3xl font-semibold tracking-tight underline transition-colors first:mt-0 max-md:text-2xl",
      h3: "scroll-m-20 font-sans text-xl font-semibold tracking-tight max-md:text-lg",
      p: "font-caption leading-7 [&:not(:first-child)]:mt-6",
      base: "font-mono",
      quote: "citation",
      code: "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
      lead: "font-sans text-xl text-muted-foreground",
      large: "text-lg font-semibold",
      small: "font-caption text-sm font-medium leading-none",
      muted: "font-caption text-sm text-muted-foreground",
      link: "font-sans font-medium text-primary hover:underline",
    },
  },
  defaultVariants: {
    variant: "base",
  },
});

type PolymorphicAsProp<E extends ElementType> = {
  as?:
    | E
    | React.ComponentType<Omit<ComponentPropsWithoutRef<E>, "as">>
    | React.FunctionComponent<Omit<ComponentPropsWithoutRef<E>, "as">>;
};

type PolymorphicProps<E extends ElementType> = PropsWithChildren<
  Omit<ComponentPropsWithoutRef<E>, "as"> & PolymorphicAsProp<E>
>;

type TypographyCvaProps = VariantProps<typeof typographyVariants>;

type EnhancedTypographyProps<E extends ElementType> = PolymorphicProps<E> &
  TypographyCvaProps & {
    capitalize?: boolean;
    duration?: number;
    delay?: number;
    right?: boolean;
    center?: boolean;
    href?: LinkProps | string;
    target?: LinkTargetProps;
  };

const defaultElement = "p";

const defaultElementMapping: Record<
  NonNullable<TypographyCvaProps["variant"]>,
  ElementType
> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  p: "p",
  quote: "blockquote" as "p",
  code: "code",
  lead: "p",
  large: "p",
  small: "small",
  muted: "p",
  link: "a",
  base: "p",
} as const;

function capitalizeText(text: React.ReactNode): React.ReactNode {
  if (typeof text === "string") {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
}

export function Typography<E extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
  variant,
  capitalize = false,
  right = false,
  center = false,
  href,
  target,
  ...restProps
}: EnhancedTypographyProps<E>) {
  const Component: ElementType = as ?? defaultElementMapping[variant ?? "base"];
  const content = capitalize ? capitalizeText(children) : children;

  const WrapperComponent = href && Component !== Link ? Link : Component;

  const onClick =
    target === LinkTargetProps.POPUP
      ? (e: MouseEvent) => {
          e.preventDefault();
          window.open(
            href as string,
            `Analyse de la performance`,
            "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=900,height=600,left=100,top=100",
          );
        }
      : restProps.onClick;

  // Remove the ref prop from here
  return (
    <WrapperComponent
      {...{
        ...(restProps as ComponentPropsWithoutRef<E>),
        target,
      }}
      className={cn(
        typographyVariants({ variant }),
        {
          "text-right": right,
          "text-center": center,
        },
        className,
      )}
      href={href}
      onClick={onClick}
    >
      {content}
    </WrapperComponent>
  );
}
